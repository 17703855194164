import React from "react"
import {Link} from "gatsby"
import {ListItem, ListItemText, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import theme from "../../assets/theme";

const item_theme = createTheme(theme,{
    root: {
        "&$selected": {
            backgroundColor: "transparent",
        },
        width: "9em",
    },
    selected: {
        backgroundColor: "transparent",
    },
    selectedText: {
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
})

const NavBarListItem = ({to, text, selectedTextClassName}) => {
    return (
        <ThemeProvider theme={item_theme}>
            <ListItem to={to} button sx={item_theme.root} component={Link}>
                <ListItemText primary={text} color="inherit"/>
            </ListItem>
        </ThemeProvider>
    )
}

export default NavBarListItem
