import React from "react"

import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Hidden, Grid, Menu, MenuItem} from "@mui/material"

import {Link} from "gatsby"
import logo from "../../assets/logo.png"

import NavBarListItem from "./NavBarListItem"

import {IconButton} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import theme from "../../assets/theme";


const nav_theme = createTheme(theme, {
    logo: {
        width: "6em",
        height: "2em",
        cursor: "pointer",
    },
    navbar: {paddingTop: "2%", width:"100%"},
    navbarItem: {paddingRight: "5%"},
    navBarItems: {
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-end",
        },
    },
    githubLogo: {
        display: "flex",
        alignItems: "center",
        width: "3em"
    },
})

const menuLinks = [
    {
        name: "Home",
        link: "/"
    },
    // {
    //     name: "Blog",
    //     link: "/blog"
    // },
    {
        name: "About Us",
        link: "/aboutus"
    },
]


const NavBar = ({items = []}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <ThemeProvider theme={nav_theme}>
            <Grid container sx={nav_theme.navbar}>
                <Grid item xs={7} container alignContent="center">
                    <Link
                        to="/"
                        sx={{
                            paddingTop: "2%", paddingRight: "5%",
                            width: "6em",
                            height: "2em",
                            cursor: "pointer",
                        }}
                        title="Logo"
                    >
                        <img src={logo} style={nav_theme.logo} alt="Knodle"/>
                    </Link>
                </Grid>
                <Grid container item xs={5} sx={theme.navBarItems}>
                    <Hidden mdUp>
                        <IconButton onClick={handleClick}>
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="navigation-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{zIndex: 3000}}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            {menuLinks.map(({name, link}) => (
                                <MenuItem onClick={handleClose} key={link}>
                                    <NavBarListItem to={link} text={name}/>
                                </MenuItem>
                            ))}
                            <Grid item sx={theme.githubLogo} justify="center">
                                <MenuItem onClick={handleClose} key={"github"}>
                                    <a href="https://github.com/knodle/knodle" target="_blank">
                                        <GitHubIcon/>
                                    </a>
                                </MenuItem>
                            </Grid>
                        </Menu>
                    </Hidden>
                    <Hidden smDown>
                        {menuLinks.map(({name, link}) => (
                            <Grid key={name} item sx={theme.navbarItem}>
                                <NavBarListItem to={link} text={name}/>
                            </Grid>
                        ))}
                        <Grid item sx={nav_theme.githubLogo}>
                            <a href="https://github.com/knodle/knodle" target="_blank">
                                <GitHubIcon/>
                            </a>
                        </Grid>
                        <Grid item sx={nav_theme.githubLogo}>
                            <a href="https://twitter.com/knodle_ai" target="_blank">
                                <TwitterIcon/>
                            </a>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default NavBar
