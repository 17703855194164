import * as React from "react"
import {Container} from "@mui/material";
import NavBar from "./NavBar";

const Layout = ({ children }) => {
    return (
        <Container maxWidth="lg">
            <NavBar />
            <div>{children}</div>
        </Container>
    )
}

export default Layout