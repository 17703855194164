
import {createTheme} from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#f09fc1",
      light: "#ffd1f4",
      dark: "#bc6f91",
    },
    secondary: {
      main: "#000",
      light: "#000",
      dark: "#000",
    },
    background: {
      default: "#fff",
    },

    text: {
      primary: "#000000",
      secondary: "rgba(17,17,17,0.62)",
    },
    grey: {
      100: "#c9c9c9",
    },
  },
  typography: {
    fontFamily: "Montserrat, serif",
    fontStyle: "normal",
    fontWeight: 100,
    fontSize: 12,
    h1: {
      fontWeight: 100,
    },
    h2: {
      fontWeight: 100,
    },

    h3: {
      fontWeight: 100,
    },

    h4: {
      color: "black",
      fontWeight: 100,
    },
    body2: {
      fontSize: "1em",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
      },
    },
  },
})

export default theme
